import React from "react";
import ButtonPaginate from "./ButtonPaginate";
import { MdFastForward, MdFastRewind, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Paginate = ({ setMeta, meta, action, setAction, showDetails }) => {
    const setNewMeta = meta => {
        setMeta({
            ...meta,
            current_page: meta,
        });
        setAction(action + 1);
    };

    return (
        <>
            <div className="flex mt-3 justify-center">
                <ButtonPaginate
                    disabled={meta.current_page === 1}
                    onClick={() => {
                        setNewMeta(1);
                        window.scrollTo(0, 0);
                    }}
                >
                    <MdFastRewind />
                </ButtonPaginate>
                <ButtonPaginate
                    disabled={meta.current_page === 1}
                    onClick={() => {
                        setNewMeta(meta.current_page - 1);
                        window.scrollTo(0, 0);
                    }}
                >
                    <MdKeyboardArrowLeft />
                </ButtonPaginate>

                <ButtonPaginate
                    disabled={meta.current_page === meta.total_pages}
                    onClick={() => {
                        setNewMeta(meta.current_page + 1);
                        window.scrollTo(0, 0);
                    }}
                >
                    <MdKeyboardArrowRight />
                </ButtonPaginate>
                <ButtonPaginate
                    disabled={meta.current_page === meta.total_pages}
                    onClick={() => {
                        setNewMeta(meta.total_pages);
                        window.scrollTo(0, 0);
                    }}
                >
                    <MdFastForward />
                </ButtonPaginate>
            </div>

            {showDetails && meta.item_count > 0 && (
                <div className="flex mt-3 justify-center">
                    Mostrando {meta.item_count ? meta.item_count : 0} de {meta.total_count ? meta.total_count : 0}
                    {meta.total_pages > 0 && (
                        <small className="mt-1 ml-1">
                            {" "}
                            (página {meta.current_page} de {meta.total_pages})
                        </small>
                    )}
                </div>
            )}
        </>
    );
};

export default Paginate;
