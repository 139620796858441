import React, { useState } from "react";
import { FaExpand, FaCompress } from "react-icons/fa";
import "./style.css";

const XpandContainer = ({ children }) => {
    const [isShow, setIsShow] = useState(false);
    return (
        <section className={`xpand-container ${isShow ? "full" : "box"}`}>
            <button className="btn-resize" onClick={() => setIsShow(!isShow)}>
                {isShow ? <FaCompress /> : <FaExpand />}
            </button>
            <article>{children}</article>
        </section>
    );
};

export default XpandContainer;
