import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FiDownload } from "react-icons/fi";
import Modal from "../../../components/Modals";

const ModalExport = ({ modalExport, actionModalExport, isDownloading, downloadList, title }) => {
    return (
        <Modal
            show={modalExport}
            borderTitle={false}
            onClose={actionModalExport}
            size=""
            title={`Exportar dados - ` + title}
        >
            <div className="w-full text-center mb-4 mr-4 text-center">
                <p className="mt-2 font-normal">Deseja realizar o download em qual dos formatos abaixo?</p>
                <button
                    disabled={isDownloading}
                    onClick={downloadList}
                    className="mt-4 px-3 py-1 font-medium rounded h-10 w-32 bg-roxo_oficial text-white"
                >
                    {isDownloading ? (
                        <>
                            <ClipLoader color="white" size={20} loading={isDownloading} /> EXCEL
                        </>
                    ) : (
                        <>
                            <FiDownload size="1.5rem" className="inline-block" /> EXCEL
                        </>
                    )}
                </button>
            </div>
        </Modal>
    );
};

export default ModalExport;
