import styled from "styled-components";

export const Button = styled.button`
    min-width: 109px;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #2a2a2a;
    border: 1px solid #70707080;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
`;

export const ButtonExport = styled.button`
    color: #2a2a2a;
    font-size: 0.8rem;
    border: 1px solid #70707080;
    background: #fff;
    padding: 0.5rem 1.2rem;
    border-radius: 4px;
`;

export const ColumnHeadTable = styled.div`
    min-width: ${props => (props?.width ? props?.width : "183")}px;
    padding-left: ${props => (props?.paddingLeft ? props?.paddingLeft : "0")}px;
    padding-right: ${props => (props?.paddingRight ? props?.paddingRight : "0")}px;
    padding-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: ${props => (props.align ? props.align : "left")};
`;

export const ContainerCards = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media (max-width: 1041px) {
        justify-content: center;
    }

    @media (min-width: 1921px) {
        justify-content: center;
    }
`;

export const ContainerFilters = styled.div`
    display: flex;
    align-items: center;
    ${props => (props.justify ? "justify-content: space-between;padding: 1rem 1.3rem 1.5rem 0;" : "")}

    @media (max-width: 678px) {
        margin-top: 1.4rem;
        flex-direction: column-reverse;
    }
`;

export const ContainerFluid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 678px) {
        flex-direction: column;
    }
`;

export const ContainerSection = styled.div`
    display: flex;

    @media (max-width: 678px) {
        margin-top: 0.9rem;
    }
`;

export const ContainerInput = styled.div`
    min-width: 30rem;
    height: 100%;
    border: 1px solid #70707080;
    border-radius: 4px;
    padding: 0.6rem 0 0.6rem 1rem;

    display: flex;
    align-items: center;
`;

export const ContainerLines = styled.div`
    overflow-x: scroll;
`;

export const ContainerPie = styled.div``;

export const ContainerSearch = styled.div`
    margin-left: 0.9rem;
    height: 100%;
    display: flex;

    @media (max-width: 678px) {
        margin-bottom: 1.1rem;
    }
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
`;

export const IconCursor = styled.div`
    cursor: pointer;
`;

export const Input = styled.input`
    width: 90%;
    font-size: 0.8rem;
    font-weight: normal;
    padding-left: 0.9rem;
    outline: none;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #707070;
        font-weight: normal;
    }

    @media (max-width: 678px) {
        height: 30px;
    }
`;

export const LineHeadTable = styled.div`
    width: fit-content;
    display: flex;
    margin-top: 2rem;
    border-bottom: 1px solid #422c76;
`;

export const LineHeadText = styled.p`
    margin-right: 0.4rem;
    cursor: ${props => (props.cursor ? "pointer" : "default")};
`;

export const Table = styled.div`
    background: #fff;
`;

export const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 678px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

export const ButtonIconText = styled.p`
    color: #707070;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 7px;
`;

export const ButtonIcon = styled.div`
    height: 2rem;
    border: 1px solid #909090;
    border-radius: 4px;
    display: inline-block;
    padding: 0.32rem 1.2rem;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
        margin-left: 0.44rem;
    }
`;
