import React, { useState } from "react";
import Modal from "../../../../components/Modals";
import Input from "../../../../components/Input/Input";
import requests from "../../../../services/requests";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import api from "../../../../services/api";

const yup = require("yup");

const ModalRemove = ({ actionModal, setTabModal, openModal, handleSuccess, pickingPack }) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Deseja remover Picking & Pack de nota {pickingPack?.nuNota}?
                    </h5>
                </div>
            }>
            <p className="text-center">Esta ação não poderá ser desfeita. Deseja continuar?</p>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    setLoading(true);
                    requests
                        .deletePickingPack(pickingPack.identifier)
                        .then(() => {
                            setLoading(false);
                            toast.success("Picking & Pack de nota " + pickingPack.nuNota + " removido com sucesso!");
                            handleSuccess();
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }}
            >
                Confirmar {loading && <ClipLoader size="13" color="white" />}
            </button>
        </Modal>
    );
};
export default ModalRemove;
