import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import ModalRemove from "./components/ModalRemove";
import permissions from "../../../services/permissions";
import profile from "../../../services/profile";

import * as S from "../styles";

const LineTablePicking = ({ entity, getList }) => {
    const [modalLineTable, setModalLineTable] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [currentPickingPack, setCurrentPickingPack] = useState(false);

    const handleColor = status => {
        if (status === "A") {
            return "#06F90E";
        } else if (status === "B") {
            return "#F8991C";
        } else if (status === "C") {
            return "#FB1B41";
        } else return "";
    };

    const handleModalLineTable = () => {
        setModalLineTable(!modalLineTable);
    };

    const actionModalRemove = currentPickingPack => {
        setCurrentPickingPack(currentPickingPack);
        setOpenModalRemove(!openModalRemove);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    return (
        <>
            <ModalRemove
                actionModal={closeModalRemove}
                openModal={openModalRemove}
                pickingPack={currentPickingPack}
                handleSuccess={() => {
                    closeModalRemove();
                    getList();
                }}
            />
            <S.LineBodyTable key={entity?.id} className={modalLineTable ? "" : "border-b-2"}>
                <S.Columns>
                    {profile.role === "ROLE_SYSTEM_ADMIN" && (
                        <S.ColumnBodyTable onClick={handleModalLineTable} width="150">
                            <S.LineBodyText>{entity?.idNotafiscal}</S.LineBodyText>
                        </S.ColumnBodyTable>
                    )}
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="225">
                        <S.BoxIcon>
                            {!modalLineTable ? <IoIosArrowForward color="#252525" /> : <IoIosArrowDown color="#252525" />}
                        </S.BoxIcon>
                        <S.LineBodyText>{entity?.statusNf}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="170">
                        <S.LineBodyText>{entity?.usuarioConferencia}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="120">
                        <S.LineBodyText>{entity?.stateCodeFormatted}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="140" align="right" paddingRight="40">
                        <S.LineBodyText>{entity?.nuNota}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="50" align="left" align="right">
                        <S.LineBodyText>{entity?.serieNf}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="170" align="right" paddingRight="30">
                        <S.LineBodyText>{entity?.idPedido}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="140" paddingLeft="15">
                        <S.LineBodyText>{entity?.classTpPedido}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="400" paddingLeft="15">
                        <S.LineBodyText>{entity?.nomeDepositante}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="400" paddingLeft="15">
                        <S.LineBodyText>{entity?.nomeTransportadora}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable width="160" paddingLeft="15">
                        <S.LineBodyText>{entity?.ufDestinatario}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="400" paddingLeft="15">
                        <S.LineBodyText>{entity?.recipientCity}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="400" paddingLeft="15">
                        <S.LineBodyText>{entity?.nomeEntrega}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" paddingLeft="15">
                        <S.LineBodyText>{entity?.dtCadastroFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" paddingLeft="15">
                        <S.LineBodyText>{entity?.dtInicioSeparacaoFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" paddingLeft="15">
                        <S.LineBodyText>{entity?.dtFimSeparacaoFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" paddingLeft="15">
                        <S.LineBodyText>{entity?.dtInicioConferenciaFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" paddingLeft="15">
                        <S.LineBodyText>{entity?.dtFimConferenciaFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="170" align="right" paddingRight="50">
                        <S.LineBodyText>{entity?.qtVolumesFormatted ?? "-"}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable width="120" align="right" paddingRight="70">
                        <S.LineBodyText>{entity?.cubagemM3Formatted}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="200" align="right" paddingRight="100">
                        <S.LineBodyText>{entity?.valorNfFormatted}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    <S.ColumnBodyTable onClick={handleModalLineTable} width="150" align="right" paddingRight="75">
                        <S.LineBodyText>{entity?.weightFormatted}</S.LineBodyText>
                    </S.ColumnBodyTable>
                    {profile.role !== "ROLE_CLIENT" && (permissions.excludePickingPack || profile.role === "ROLE_SYSTEM_ADMIN") && (
                        <td>
                            <button
                                className="p-2 rounded-md bg-canal_vermelho"
                                title="Excluir picking & pack"
                                onClick={() => actionModalRemove(entity)}
                            >
                                <FaTrashAlt color="#fff" />
                            </button>
                        </td>
                    )}
                </S.Columns>
                {modalLineTable && (
                    <S.CardInfoLine>
                        <S.CardTable>
                            <S.CardTableLine>
                                <S.CardTableColumn width="240">
                                    <S.LineHeadText>Código</S.LineHeadText>
                                </S.CardTableColumn>
                                <S.CardTableColumn width="500">
                                    <S.LineHeadText>Descrição</S.LineHeadText>
                                </S.CardTableColumn>
                                <S.CardTableColumn width="130">
                                    <S.LineHeadText>Quantidade</S.LineHeadText>
                                </S.CardTableColumn>
                                <S.CardTableColumn>
                                    <S.LineHeadText>Lote Indústria</S.LineHeadText>
                                </S.CardTableColumn>
                                <S.CardTableColumn width="350">
                                    <S.LineHeadText>Número de série</S.LineHeadText>
                                </S.CardTableColumn>
                                <S.CardTableColumn align="center">
                                    <S.LineHeadText>Curva ABC</S.LineHeadText>
                                </S.CardTableColumn>
                            </S.CardTableLine>
                            {entity?.itens && entity?.itens?.length > 0 ? (
                                entity?.itens?.map((entity2, key) => {
                                    return (
                                        <S.CardTableLineBody>
                                            <S.CardTableColumnBody width="240">
                                                <S.LineBodyText>{entity2?.codProduto}</S.LineBodyText>
                                            </S.CardTableColumnBody>
                                            <S.CardTableColumnBody width="500">
                                                <S.LineBodyText>{entity2?.descricaoProduto}</S.LineBodyText>
                                            </S.CardTableColumnBody>
                                            <S.CardTableColumnBody width="130" align="right" paddingRight="40">
                                                <S.LineBodyText>{entity2?.qtdeExpedidaFormatted}</S.LineBodyText>
                                            </S.CardTableColumnBody>
                                            <S.CardTableColumnBody>
                                                <S.LineBodyText>{entity2?.loteIndustrial}</S.LineBodyText>
                                            </S.CardTableColumnBody>
                                            <S.CardTableColumnBody width="350" paddingRight="40">
                                                <S.LineBodyText>{entity2?.infoEspecifica}</S.LineBodyText>
                                            </S.CardTableColumnBody>
                                            <S.CardTableColumnBody align="center">
                                                <S.LineBodyText color={handleColor(entity2?.inAbc)}>
                                                    {entity2?.inAbc}
                                                </S.LineBodyText>
                                            </S.CardTableColumnBody>
                                        </S.CardTableLineBody>
                                    );
                                })
                            ) : (
                                <S.LineBodyTextCenter>Nenhum dado encontrado</S.LineBodyTextCenter>
                            )}
                        </S.CardTable>
                    </S.CardInfoLine>
                )}
            </S.LineBodyTable>
        </>
    );
};

export default LineTablePicking;
