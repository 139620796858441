import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsArrowUpDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import * as S from "../styled/styles";

import Layout from "../../../components/Layout";
import HeaderArmazem from "../../../components/HeaderArmazem";
import ModalExport from "../components/ModalExport";
import LineTablePicking from "../../../components/Lines/LineTablePicking";
import Paginate from "../../../components/Paginate/Paginate";
import requests from "../../../services/requests";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import ExportarIcon from "../../../assets/svgs/exportar.inline.svg";
import permissions from "../../../services/permissions";
import profile from "../../../services/profile";
import OrderStatusGraph from "../../../components/Graphs/OrderStatusGraph";
import OrderTimingGraph from "../../../components/Graphs/OrderTimingGraph";

import XpandContainer from "../../../components/XpandContainer";

const IndexPage = () => {
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(
        window.localStorage.getItem("warehouse-filter-client")
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-client"))
            : []
    );

    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [action, setAction] = useState(1);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [searchNfe, setSearchNfe] = useState(null);
    const [selectedUf, setSelectedUf] = useState(
        JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.value !== null
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-state"))
            : { value: null, label: "TODOS" }
    );
    const searchPeriod = useSelector(state => state.period);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [liberados, setLiberados] = useState(0);
    const [bloqueados, setBloqueados] = useState(0);
    const [agLiberacao, setAgLiberacao] = useState(0);
    const [agFaturamento, setAgFaturamento] = useState(0);
    const [agColeta, setAgColeta] = useState(0);
    const [separacao, setSeparacao] = useState(0);
    const [conferencia, setConferencia] = useState(0);
    const [orderList, setOrderList] = useState(null);
    const [modalExport, setModalExport] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [statusPedido, setStatusPedido] = useState(null);
    const [statusGrafico, setStatusGrafico] = useState(null);

    //Função que gera arquivo para exportar lista
    const downloadList = () => {
        setIsDownloading(true);
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "picking",
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        var date = new Date();

        requests
            .exportListPickingExpedicao(filters)
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", 'PickingPack' + '-' + date.getFullYear() + date.getMonth() + date.getDate() + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloading(false);
                setModalExport(false);
            });
    };

    //Função que gera os gráficos
    const showMetrics = () => {
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "picking",
            statusPedido,
            statusGrafico,
        };

        requests.dashboardPickingPack(filters, "picking").then(data => {
            setLiberados(data.data.liberados);
            setBloqueados(data.data.bloqueados);
            setAgLiberacao(data.data.ag_liberacao);
            setAgFaturamento(data.data.ag_faturamento);
            setAgColeta(data.data.ag_coleta);
            setSeparacao(data.data.separacao);
            setConferencia(data.data.conferencia);
        });
    };

    //Função que gera a listagem
    const listPickingPack = () => {
        setLoad(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            type_storage: "picking",
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        requests.listPickingPack(filters, meta.current_page > 0 ? meta.current_page : 1).then(data => {
            setList(data.data);
            setMeta(data.meta);
            setLoad(false);
        });
    };

    //Função que gera a lista de clientes do filtro
    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (selectedClient?.length > 0 && search.length < 2) {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        } else if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const handleSearchNfe = number => {
        setSearchNfe(number);
    };

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const validateOrder = (object, column) => {
        if (object?.order_campo === column) {
            return true;
        } else {
            return false;
        }
    };

    const handleOrder = (campo, order = 0) => {
        if (order > 0) {
            if (order === 3) {
                if (orderList?.order_campo === campo && orderList?.order === "DESC") {
                    setOrderList({
                        order_campo: campo,
                        order: "ASC",
                    });
                } else if (orderList?.order_campo === campo && orderList?.order === "ASC") {
                    setOrderList(null);
                } else {
                    setOrderList({
                        order_campo: campo,
                        order: "DESC",
                    });
                }
            } else {
                setOrderList({
                    order_campo: campo,
                    order: order === 1 ? "DESC" : "ASC",
                });
            }
        } else {
            setOrderList(null);
        }
    };

    useEffect(() => {
        loadClientOptions();

        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
        }
    }, []);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            showMetrics();
            listPickingPack();
        }
    }, [searchPeriod, selectedClient, selectedUf, statusGrafico]);

    useEffect(() => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            listPickingPack();
        }
    }, [action, statusPedido, searchNfe, orderList]);

    return (
        <Layout>
            <ModalExport
                modalExport={modalExport}
                actionModalExport={actionModalExport}
                isDownloading={isDownloading}
                downloadList={downloadList}
                title="Picking e Packing"
            />

            <HeaderArmazem
                title="Armazém > Picking e Packing"
                clientsOptions={clientsOptions}
                setSelectedClient={setSelectedClient}
                noClientOptionsMessage={noClientOptionsMessage}
                loadClientOptions={loadClientOptions}
                isLoadingClients={isLoadingClients}
                setSelectedUf={setSelectedUf}
                setMeta={setMeta}
                handleStatusGrafico={setStatusGrafico}
                handleStatusPedido={setStatusPedido}
            />

            <S.ContainerCards className="flex flex-wrap p-4">
                <Card className="w-3/12 md-full">
                    <OrderTimingGraph
                        modality="STATUS_PEDIDO"
                        title="Status dos Pedidos"
                        searchPeriod={searchPeriod}
                        selectedClient={selectedClient}
                        selectedUf={selectedUf}
                        handleStatus={setStatusGrafico}
                        statusGrafico={statusGrafico}
                        statusPedido={statusPedido}
                        setMeta={setMeta}
                    />
                </Card>
                <Card className="w-9/12 -mr-2 ml-2 md-full">
                    <OrderStatusGraph
                        modality="STATUS_PEDIDO"
                        title="Andamento dos Pedidos"
                        searchPeriod={searchPeriod}
                        selectedClient={selectedClient}
                        selectedUf={selectedUf}
                        handleStatus={setStatusPedido}
                        statusGrafico={statusGrafico}
                        statusPedido={statusPedido}
                        setMeta={setMeta}
                    />
                </Card>
            </S.ContainerCards>
            <div className="p-4">
                <Card>
                    <S.Table>
                        <S.TableHeader>
                            <S.ContainerFluid>
                                <S.ContainerSection>
                                    <TitleC6>Picking & Packing</TitleC6>
                                    <S.ContainerSearch>
                                        <S.ContainerInput>
                                            <BiSearch color="#00000087" />
                                            <S.Input
                                                placeholder="Buscar por NFE"
                                                onChange={event => handleSearchNfe(event.target.value)}
                                            />
                                        </S.ContainerInput>
                                    </S.ContainerSearch>
                                </S.ContainerSection>
                                <S.ContainerSection>
                                    <S.ButtonIcon>
                                        <S.ButtonIconText onClick={() => setModalExport(!modalExport)}>
                                            Exportar
                                        </S.ButtonIconText>
                                        <ExportarIcon />
                                    </S.ButtonIcon>
                                </S.ContainerSection>
                            </S.ContainerFluid>
                        </S.TableHeader>
                        <S.ContainerLines>
                            <XpandContainer>
                                <S.LineHeadTable>
                                    {profile.role === "ROLE_SYSTEM_ADMIN" && (
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.idNotafiscal", 3)}
                                                cursor>
                                                ID NF (Sênior)
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.idNotafiscal") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.idNotafiscal", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.idNotafiscal", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.idNotafiscal", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                    )}
                                    <S.ColumnHeadTable width="225">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.statusNf", 3)}
                                            cursor
                                        >
                                            Status
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.statusNf") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.statusNf", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.statusNf", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.statusNf", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="170">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.usuarioConferencia", 3)}
                                            cursor
                                        >
                                            Usuário
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.usuarioConferencia") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.usuarioConferencia", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp
                                                        size={20}
                                                        onClick={() => handleOrder("e.usuarioConferencia", 0)}
                                                    />
                                                )
                                            ) : (
                                                <BsArrowUpDown
                                                    size={20}
                                                    onClick={() => handleOrder("e.usuarioConferencia", 1)}
                                                />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="120">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.ufArmazem", 3)}
                                            cursor>
                                            Armazém
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.ufArmazem") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.ufArmazem", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.ufArmazem", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.ufArmazem", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="140">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nuNota", 3)}
                                            cursor
                                        >
                                            Nota fiscal
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nuNota") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nuNota", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nuNota", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nuNota", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="100">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.serieNf", 3)}
                                            cursor>
                                            Série
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.serieNf") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.serieNf", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.serieNf", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.serieNf", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="170" align="right" paddingRight="30">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.idPedido", 3)}
                                            cursor
                                        >
                                            Pedido
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.idPedido") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.idPedido", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.idPedido", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.idPedido", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="140">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.classTpPedido", 3)}
                                            cursor
                                        >
                                            Canal
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.classTpPedido") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.classTpPedido", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.classTpPedido", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.classTpPedido", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="400">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nomeDepositante", 3)}
                                            cursor>
                                            Depositante
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nomeDepositante") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nomeDepositante", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nomeDepositante", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nomeDepositante", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="400">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nomeTransportadora", 3)}
                                            cursor>
                                            Transportadora
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nomeTransportadora") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nomeTransportadora", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nomeTransportadora", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nomeTransportadora", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="160">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.ufDestinatario", 3)}
                                            cursor>
                                            UF Destinatário
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.ufDestinatario") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.ufDestinatario", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp
                                                        size={20}
                                                        onClick={() => handleOrder("e.ufDestinatario", 0)}
                                                    />
                                                )
                                            ) : (
                                                <BsArrowUpDown
                                                    size={20}
                                                    onClick={() => handleOrder("e.ufDestinatario", 1)}
                                                />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="400">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.recipientCity", 3)}
                                            cursor>
                                            Cidade destinatário
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.recipientCity") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.recipientCity", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.recipientCity", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.recipientCity", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="400">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nomeEntrega", 3)}
                                            cursor>
                                            Destinatário
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nomeEntrega") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nomeEntrega", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nomeEntrega", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nomeEntrega", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.dtCadastro", 3)}
                                            cursor>
                                            Data de cadastro
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.dtCadastro") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtCadastro", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.dtCadastro", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtCadastro", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.dtRoterizado", 3)}
                                            cursor
                                        >
                                            Início da separação
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.dtRoterizado") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtRoterizado", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.dtRoterizado", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtRoterizado", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.dtFimSeparacao", 3)}
                                            cursor
                                        >
                                            Término da separação
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.dtFimSeparacao") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtFimSeparacao", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtFimSeparacao", 0)}
                                                    />
                                                )
                                            ) : (
                                                <BsArrowUpDown
                                                    size={20}
                                                    onClick={() => handleOrder("e.dtFimSeparacao", 1)}
                                                />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.dtInicioConferencia", 3)}
                                            cursor
                                        >
                                            Início da conferência
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.dtInicioConferencia") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtInicioConferencia", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtInicioConferencia", 0)}
                                                    />
                                                )
                                            ) : (
                                                <BsArrowUpDown
                                                    size={20}
                                                    onClick={() => handleOrder("e.dtInicioConferencia", 1)}
                                                />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.dtFimConferencia", 3)}
                                            cursor
                                        >
                                            Término da conferência
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.dtFimConferencia") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtFimConferencia", 2)}
                                                    />
                                                ) : (
                                                    <BsArrowUp
                                                        size={20}
                                                        onClick={() => handleOrder("e.dtFimConferencia", 0)}
                                                    />
                                                )
                                            ) : (
                                                <BsArrowUpDown
                                                    size={20}
                                                    onClick={() => handleOrder("e.dtFimConferencia", 1)}
                                                />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="170" paddingLeft="50">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.qtVolumes", 3)}
                                            cursor>
                                            Volumes
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.qtVolumes") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.qtVolumes", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.qtVolumes", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.qtVolumes", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="120">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.cubagemM3", 3)}
                                            cursor>
                                            Cub.
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.cubagemM3") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.cubagemM3", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.cubagemM3", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.cubagemM3", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="200" align="right" paddingRight="100">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.valorNf", 3)}
                                            cursor
                                        >
                                            Valor
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.valorNf") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.valorNf", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.valorNf", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.valorNf", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="170" align="right" paddingRight="50">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.valorNf", 3)}
                                            cursor>
                                            Peso bruto
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.pesoBrutoKg") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.pesoBrutoKg", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.pesoBrutoKg", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.pesoBrutoKg", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="500">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nomeDepositante", 3)}
                                            cursor>
                                            Depositante
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nomeDepositante") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nomeDepositante", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nomeDepositante", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nomeDepositante", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    <S.ColumnHeadTable width="500">
                                        <S.LineHeadText
                                            className="text-roxo_oficial"
                                            onClick={() => handleOrder("e.nomeEntrega", 3)}
                                            cursor
                                        >
                                            Destinatário
                                        </S.LineHeadText>
                                        <S.IconCursor>
                                            {validateOrder(orderList, "e.nomeEntrega") ? (
                                                orderList.order === "DESC" ? (
                                                    <BsArrowDown size={20} onClick={() => handleOrder("e.nomeEntrega", 2)} />
                                                ) : (
                                                    <BsArrowUp size={20} onClick={() => handleOrder("e.nomeEntrega", 0)} />
                                                )
                                            ) : (
                                                <BsArrowUpDown size={20} onClick={() => handleOrder("e.nomeEntrega", 1)} />
                                            )}
                                        </S.IconCursor>
                                    </S.ColumnHeadTable>
                                    {profile.role !== "ROLE_CLIENT" && (permissions.excludePickingPack || profile.role === "ROLE_SYSTEM_ADMIN") && (
                                        <S.ColumnHeadTable width="50">
                                            <S.LineHeadText className="text-roxo_oficial">
                                                Ações
                                            </S.LineHeadText>
                                        </S.ColumnHeadTable>
                                    )}
                                </S.LineHeadTable>
                                {!load && (
                                    <>
                                        {list &&
                                            list.map((entity, key) => {
                                                return <LineTablePicking entity={entity} getList={listPickingPack} />;
                                            })}
                                    </>
                                )}
                                {load ? (
                                    <div className="text-center mt-4">
                                        <ClipLoader size={40} loading={load} />
                                    </div>
                                ) : (
                                    <>{list.length === 0 && <div className="text-center mt-4">Nenhum dado encontrado</div>}</>
                                )}
                                {meta.total_pages > 1 && (
                                    <Paginate
                                        meta={meta}
                                        setMeta={setMeta}
                                        action={action}
                                        setAction={setAction}
                                        showDetails={true}
                                    />
                                )}
                            </XpandContainer>
                        </S.ContainerLines>
                    </S.Table>
                </Card>
            </div>
        </Layout>
    );
};

export default IndexPage;
