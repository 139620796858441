import React, { useEffect } from "react";
import Select from "react-select";

import SEO from "../SEO";
import ConsultationByPeriod from "../../components/ConsultationByPeriod";
import CardFull from "../../components/Cards/CardFull";

const HeaderArmazem = ({
    title,
    clientsOptions,
    setSelectedClient,
    noClientOptionsMessage,
    loadClientOptions,
    isLoadingClients,
    setSelectedUf,
    setMeta,
    handleStatusGrafico,
    handleStatusPedido,
    dashboard = false,
    isByPeriod = true
}) => {
    const options = [
        { value: null, label: "TODOS" },
        { value: 5, label: "SC - matriz" },
        { value: 7, label: "SC - filial 1" },
        { value: 6, label: "SP - filial 1" },
    ];

    useEffect(() => {
        if (!window.localStorage.getItem("warehouse-filter-state")) {
            window.localStorage.setItem("warehouse-filter-state", JSON.stringify(options[0]));
            setSelectedUf(options[0]);
        }
    }, []);

    return (
        <>
            <SEO title={title} />
            <CardFull className="justify-between flex xs:flex-wrap lg:flex-wrap flex-col lg:h-auto">
                <div className="flex xs:w-full lg:w-100 md-col items-center mb-2">
                    {isByPeriod && (
                        <ConsultationByPeriod
                            forArmazem={true}
                            setMeta={setMeta}
                            dashboard={dashboard}
                            handleStatusGrafico={handleStatusGrafico}
                            handleStatusPedido={handleStatusPedido}
                        />
                    )}

                    <div className="flex md-full xs:w-full lg:w-2/5 items-center pr-10 ml-5">
                        <Select
                            isMulti
                            id="client"
                            name="client"
                            options={clientsOptions}
                            value={clientsOptions.filter(
                                option =>
                                    JSON.parse(window.localStorage.getItem("warehouse-filter-client"))?.indexOf(
                                        option.value
                                    ) > -1
                            )}
                            onChange={item => {
                                setMeta({
                                    current_page: 1,
                                });
                                let selectedClient = [];

                                selectedClient = item?.map(client => client?.value);

                                if (!dashboard) {
                                    handleStatusGrafico(null);
                                    handleStatusPedido(null);
                                }

                                if (selectedClient?.length > 0) {
                                    window.localStorage.setItem("warehouse-filter-client", JSON.stringify(selectedClient));
                                } else {
                                    window.localStorage.removeItem("warehouse-filter-client");
                                }

                                setSelectedClient(selectedClient);
                            }}
                            noOptionsMessage={() => noClientOptionsMessage}
                            onInputChange={inputValue => {
                                loadClientOptions(inputValue);
                            }}
                            isLoading={isLoadingClients}
                            className="basic-multi-select w-full text-sm outline-none transform-none"
                            placeholder="Buscar por cliente"
                            isClearable
                        />
                    </div>
                    <div className="xs:w-full md-full lg:w-1/5 xs:mb-2 lg:mb-0">
                        <Select
                            options={options}
                            id="idArmazem"
                            name="idArmazem"
                            value={options.filter(
                                option => option.label === JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.label
                            )}
                            className="outline-none transform-none"
                            placeholder="Buscar por estado"
                            onChange={item => {
                                setMeta({
                                    current_page: 1,
                                });

                                window.localStorage.setItem("warehouse-filter-state", JSON.stringify(item));

                                if (!dashboard) {
                                    handleStatusGrafico(null);
                                    handleStatusPedido(null);
                                }

                                setSelectedUf(item);
                            }}
                        />
                    </div>
                </div>
            </CardFull>
        </>
    );
};

export default HeaderArmazem;
