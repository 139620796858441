import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";

import requests from "../../../services/requests";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import { customLabels, customLegend, customMarkers } from "../shared/layout";
import { createSeries } from "../shared/createElements";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
am4core.useTheme(am4themesAnimated);

export default function OrderStatusGraph({
    modality,
    title,
    searchPeriod,
    selectedClient,
    selectedUf,
    statusGrafico,
    statusPedido,
    handleStatus,
    setMeta,
    dashboard = false,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(true);
    const filtered = useRef(null);

    const x = useRef(null);

    const getData = useCallback(async chart => {
        if (modality === "STATUS_PEDIDO") {
            if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
                requests
                    .dashboardPickingPack({
                        ...searchPeriod,
                        idArmazem: selectedUf?.value,
                        clientsWarehouse: selectedClient,
                        type_storage: "picking",
                        statusPedido,
                        statusGrafico,
                    }, "picking")
                    .then(data => {
                        let aux = {
                            category: "",
                            agLiberacao: data.data.aguardandoLiberacao,
                            agFaturamento: data.data.aguardandoFaturamento,
                            agColeta: data.data.aguardandoColeta,
                            separacao: data.data.emSeparacao,
                            conferencia: data.data.emConferencia,
                            cancelado: data.data.cancelado,
                        };

                        chart.data = [aux];

                        setIsLoading(false);
                    });
            }
        }

        chart.data = [];
    });

    useLayoutEffect(() => {
        const chart = am4core.create("OrderStatusGraph", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;

        chart.colors.list = [
            am4core.color("#00D078"),
            am4core.color("#43DF9E"),
            am4core.color("#00A661"),
            am4core.color("#f8991c"),
            am4core.color("#dd7e02"),
            am4core.color("#FF0000"),
        ];

        // Create axes
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.opposite = true;

        const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
        xAxis.max = 100;
        xAxis.strictMinMax = true;
        xAxis.calculateTotals = true;
        xAxis.renderer.minHeight = 40;
        xAxis.renderer.disabled = true;
        xAxis.renderer.grid.template.disabled = true;

        const xLabels = xAxis.renderer.labels;
        customLabels(xLabels);

        let seriesData = [
            {
                seq: 0,
                key: "agLiberacao",
                name: "Aguardando liberação",
                status: "aguardandoLiberacao",
            },
            {
                seq: 1,
                key: "agFaturamento",
                name: "Aguardando faturamento",
                status: "aguardandoFaturamento",
            },
            {
                seq: 2,
                key: "agColeta",
                name: "Aguardando coleta",
                status: "aguardandoColeta",
            },
            {
                seq: 3,
                key: "separacao",
                name: "Em separação",
                status: "emSeparacao",
            },
            {
                seq: 4,
                key: "conferencia",
                name: "Em conferência",
                status: "emConferencia",
            },
            {
                seq: 5,
                key: "cancelado",
                name: "Cancelado",
                status: "cancelado",
            },
        ];

        for (let i = 0; i < seriesData.length; i++) {
            let series = createSeries(chart, seriesData[i].key, seriesData[i].name, 7, function (ev) {
                if (handleStatus) {
                    if (!dashboard) {
                        setMeta({
                            current_page: 1,
                        });
                    }

                    if (filtered.current === seriesData[i].key) {
                        filtered.current = null;
                        handleStatus(null);
                    } else {
                        filtered.current = seriesData[i].key;
                        handleStatus(seriesData[i].status);
                    }

                    chart.series.each(function (series, k) {
                        if (series instanceof am4charts.ColumnSeries) {
                            series.columns.each(function (column) {
                                if (filtered.current === null) {
                                    column.isActive = false; // Se não tiver filtro, desativa o filtro em todos
                                } else if (k !== seriesData[i].seq && filtered.current === seriesData[i].key) {
                                    column.isActive = true; // Se o escolhido for o A, ativa o filtro nos outros que não são o A
                                } else {
                                    column.isActive = false;
                                }
                            });
                        }
                    });
                }
            });

            let tActiveState = series.columns.template.states.create("active");
            tActiveState.properties.fill = "#CECECE";
        }

        const legend = customLegend(chart);
        customMarkers(legend);

        legend.maxHeight = undefined;
        legend.itemContainers.template.togglable = false;

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchPeriod, selectedClient, selectedUf]);

    return (
        <>
            {(isLoading || total > 0) && (
                <Card>
                    <TitleC6>{title}</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                        {isLoading && <ClipLoader size={20} loading={isLoading} />}

                        <div id="OrderStatusGraph" style={{ width: "100%", height: "250px" }} />
                    </div>
                </Card>
            )}
        </>
    );
}
