import styled from "styled-components";

export const BoxIcon = styled.div`
    margin-right: 0.6rem;
`;

export const ButtonExport = styled.button`
    color: #2a2a2a;
    font-size: 0.7rem;
    border: 1px solid #70707080;
    background: #fff;
    padding: 0.5rem 1.4rem;
    border-radius: 4px;
`;

export const CardInfoLine = styled.div`
    border-radius: 6px;
    border: 1px solid #70707080;
    max-width: 85vw;
    margin: 1rem 0 1rem 1.5rem;
    padding: 21px 45px;
    background: #ebebeb4d;

    @media (max-width: 678px) {
        max-width: 1200px;
    }
`;

export const CardTable = styled.div`
    margin-top: 2rem;
`;

export const CardTableColumn = styled.div`
    width: ${props => (props?.width ? props?.width : "200")}px;
    text-align: ${props => (props?.align ? props?.align : "left")};
    padding-right: ${props => (props?.paddingRight ? props?.paddingRight : "0")}px;
    padding-bottom: 0.8rem;
`;

export const CardTableColumnBody = styled.div`
    width: ${props => (props.width ? props.width : "200")}px;
    overflow: hidden;
    ${props => (props?.flex ? "display: flex;align-items: center;" : "")};
    text-align: ${props => (props?.align ? props?.align : "left")};
    word-break: break-all;
    padding-right: ${props => (props?.paddingRight ? props?.paddingRight : "0")}px;
    padding-bottom: 0.7rem;
`;

export const CardTableLine = styled.div`
    display: flex;
    border-bottom: 1px solid #7070708c;
    padding-bottom: 1.2rem;
`;

export const CardTableLineBody = styled.div`
    margin: 0.7rem 0;
    display: flex;
    border-bottom: 1px solid #b3b0b0;
`;

export const Columns = styled.div`
    display: flex;
    cursor: pointer;
`;

export const ColumnBodyTable = styled.div`
    min-width: ${props => (props?.width ? props?.width : "183")}px;
    width: ${props => (props?.width ? props?.width : "183")}px;
    padding-left: ${props => (props?.paddingLeft ? props?.paddingLeft : "0")}px;
    padding-right: ${props => (props?.paddingRight ? props?.paddingRight : "0")}px;
    padding-bottom: 0.8rem;

    display: flex;
    justify-content: ${props => (props.align ? props.align : "left")};
`;

export const LineBodyTable = styled.div`
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
    width: min-content;

    width: fit-content;
`;

export const LineBodyText = styled.p`
    color: ${props => (props.color ? props.color : "#6a6a6a")};
    font-size: 1rem;
    font-weight: 400;
`;

export const LineBodyTextCenter = styled.p`
    color: ${props => (props.color ? props.color : "#6a6a6a")};
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-top: 1rem;
`;

export const LineHeadText = styled.p`
    color: #252525;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 0.2rem;
`;
